/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider.state('overdraft', {
    parent: 'root',
    url: '/overdraft',
    templateUrl: 'apps/overdraft/templates/acp-overdraft.ng.html',
    data: {
      permissions: {
        only: 'hasOverdraftFeature',
        redirectTo: {
          hasOverdraftFeature: 'dashboard'
        }
      }
    },
    resolve: {
      $terms: /* @ngInject */ function($q, acpTermsModel) {
        return $q.all({
          esign: acpTermsModel.getEsign(),
          overdraftUrl: acpTermsModel.getTermsUrl().then(function(url) {
            // Needing to use html inside of an iframe for display purposes, and that html has an id where we need to scrollIntToView the overdraft list item.
            // It's hacky but maybe we'll have a workaround in the future.
            return url.replace('.pdf', '.html') + '#overdraft';
          })
        });
      },
      pageTitle: /* @ngInject */ function(module, $injector) {
        return $injector.invoke(nsUtilProvider.getPageTitle('apps/overdraft'));
      },
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.overdraft" */ 'apps/overdraft').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
